import { Subject, of, throwError } from "rxjs";
import { HttpErrorResponse, HttpParams } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { environment } from "./../../../environments/environment";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MessageService } from "primeng/api";

@Injectable({
  providedIn: "root",
})
export class ArticleService {
  currentPage = 0;
  pageSize = 10;
  fournisseur;
  dataToParent;
  searchArticle;
  sortFiled;
  sortMode;
  error: any | undefined;
  private isToastShown: boolean = false;

  constructor(private http: HttpClient, private snackBar: MatSnackBar, private messageService: MessageService) {}
   //**************************************handle errrors***************************************** */
   private handleApiError(error) {
    // Handle API error status here
    console.error('API call failed:', error);
  
    if (error.status !== 200) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Erreur inattendue' });
      this.isToastShown = true;
      setTimeout(() => {
        this.isToastShown = false;
      }, 5000);
    } 
    
  }
  
  async GetForexport(table): Promise<any[]> {
    let url = `${environment.url}/${table}?pagination[page]=1&pagination[pageSize]=5000&_limit=10000&populate=*`
    try {
      const apiResponse: any = await this.http.get(url).toPromise();
      return apiResponse.data 
    } catch (err) {
      console.error('Error fetching clients:', err);
      this.handleApiError(err);
          throw err; 
    }
  }
     //******************************************************************************* */

  private dataSubject = new Subject<string>();
  data$ = this.dataSubject.asObservable();

  // ReceiveData(data: any) {
  //   console.log('Data received in service:', data);
  //   this.searchArticle=data
  // }

  send(data: string) {
    this.dataSubject.next(data);
  }

  async getSort(data: any) {
    this.sortMode = data.sortMode;
    this.sortFiled = data.sortFiled;
    console.log(this.sortMode);
  }

  async sendData(data: any) {
    // Do something with the data
    console.log(data);
    if (data.fourniss) {
      this.fournisseur = data.fourniss;
    }

    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
  }
  senddataToComponent() {
    console.log(this.dataToParent);
    return this.dataToParent;
  }

  importData(data) {
    const url = `${environment.url}/createProduct`;

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.http
      .post(url, data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: any) {
    let errorMessage = "An error occurred";
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error: ${error.status} - ${error.error.error}`;
    }
    // Display a toast notification with the error message
    // Using a library like Toastr or Angular Material's MatSnackBar
    console.error(errorMessage);
    return throwError(errorMessage);
  }
  async getConfiguration() {
    let promise = new Promise<any>((resolve, reject) => {
      let url = `${environment.url}/configuration`;
      this.http
        .get(url)
        .toPromise()

        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }
  async getArticle(id) {
    let promise = new Promise<any>((resolve, reject) => {
      const params = new HttpParams();
      // .set('pagination[pageSize]',this.pageSize)
      // .set('pagination[page]', this.currentPage)
      // let url = `${environment.url}/products/${id}`;
      let url = `${environment.url}/products?filters[id]=${id}&populate=sous_famille&populate=variations
    &populate=variations.color&populate=variations.taille
    &populate=collection&populate=rayon&populate=fournisseur&populate=image&sort=id:DESC`;

      this.http
        .get(url, { params })
        .toPromise()

        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }
  async getArticles(artilce?, famille?, rayons?, collections?, fournisseur?) {
    let promise = new Promise<any>((resolve, reject) => {
      let params = new HttpParams();

      console.log("pagesize from service", this.currentPage);

      if (this.pageSize && this.pageSize !== undefined) {
        params = params.set("pagination[pageSize]", this.pageSize);
      }

      if (this.currentPage && this.currentPage !== undefined) {
        params = params.set("pagination[page]", this.currentPage);
      }

      let url = `${environment.url}/products?populate=sous_famille&populate=variations
    &populate=variations.color&populate=variations.taille
    &populate=collection&populate=rayon&populate=fournisseur&populate=image`;

      let filed = "id";
      if (this.sortFiled) {
        switch (this.sortFiled) {
          case "code_article_generique":
            filed = "code_article_generique";
            break;
          case "code_fournisseur":
            filed = "code_fournisseur";
            break;
          case "designation":
            filed = "designation";
            break;
          case "sous_famille":
            filed = "sous_famille.name";
            break;
          case "collection":
            filed = "collection.name";
            break;
          case "rayon":
            filed = "rayon.name";
            break;
          case "prix_achat_ttc":
            filed = "prix_achat_ttc";
            break;
          case "prix_vente_ttc":
            filed = "prix_vente_ttc";
            break;
          case "fournisseur":
            filed = "fournisseur.name";
            break;
          default: filed = "id"
            break;
        }
      }

      if (this.sortMode === 1) {
        url += `&sort=${filed}:DESC`;
      } else if (this.sortMode === -1) {
        url += `&sort=${filed}:ASC`;
      }

      if (artilce && artilce.length > 0) {
        for (let art of artilce) {
          url += `&filters[id][$eq]=${art}`;
        }
      }

      if (famille && famille.length > 0) {
        for (let art of famille) {
          url += `&filters[sous_famille][id][$eq]=${art}`;
        }
      }

      if (collections && collections.length > 0) {
        for (let art of collections) {
          url += `&filters[collection][id][$eq]=${art}`;
        }
      }
      if (rayons && rayons.length > 0) {
        for (let art of rayons) {
          url += `&filters[rayon][id][$eq]=${art}`;
        }
      }

      if (fournisseur && fournisseur.length > 0) {
        for (let art of fournisseur) {
          url += `&filters[fournisseur][id][$eq]=${art}`;
        }
      }

      this.http
        .get(url, { params })
        .toPromise()

        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }
  async getSearchedArticle(data: string | undefined) {
    let promise = new Promise<any>((resolve, reject) => {
      let params = new HttpParams();

      console.log("pagesize from service", this.pageSize);

      if (this.pageSize && this.pageSize !== undefined) {
        params = params.set("pagination[pageSize]", this.pageSize);
      }

      if (this.currentPage && this.currentPage !== undefined) {
        params = params.set("pagination[page]", this.currentPage);
      }

      let url = `${environment.url}/products?populate=sous_famille&populate=variations
    &populate=variations.color&populate=variations.taille
    &populate=collection&populate=rayon&populate=fournisseur&populate=image`;
      if (data) {
        url += `&filters[$or][0][code_article_generique][$containsi]=${data}&filters[$or][1][designation][$containsi]=${data}`;
      }

      let filed;
      if (this.sortFiled) {
        switch (this.sortFiled) {
          case "code_article_generique":
            filed = "code_article_generique";
            break;
          case "code_fournisseur":
            filed = "code_fournisseur";
            break;
          case "designation":
            filed = "designation";
            break;
          case "sous_famille":
            filed = "sous_famille.name";
            break;
          case "collection":
            filed = "collection.name";
            break;
          case "rayon":
            filed = "rayon.name";
            break;
          case "prix_achat_ttc":
            filed = "prix_achat_ttc";
            break;
          case "prix_vente_ttc":
            filed = "prix_vente_ttc";
            break;
          case "fournisseur":
            filed = "fournisseur.name";
            break;
          default:
            break;
        }
      }

      if (this.sortMode === 1) {
        url += `&sort=${filed}:DESC`;
      } else if (this.sortMode === -1) {
        url += `&sort=${filed}:ASC`;
      }

      this.http
        .get(url, { params })
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }
  async getArticlesWithContain(data?) {
    let promise = new Promise<any>((resolve, reject) => {
      // Convert data and attribute to lowercase for case-insensitive search
      // const searchTerm = data ? data.toLowerCase() : '';
      const searchTerm = data ? data : "";

      const attribute = "code_article_generique";

      let url = `${environment.url}/products?filters[${attribute}][$containsi]=${searchTerm}&populate=sous_famille&populate=variations&populate=variations.color&populate=variations.taille&populate=collection&populate=rayon&populate=fournisseur&populate=image&sort=id:DESC`;

      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });

    return promise;
  }
  
    async getfamillesWithContain(data?) {
    let promise = new Promise<any>((resolve, reject) => {
      // Convert data and attribute to lowercase for case-insensitive search
      // const searchTerm = data ? data.toLowerCase() : '';
      const searchTerm = data ? data : "";

      const attribute = "name";

      let url = `${environment.url}/sous-familles?filters[${attribute}][$containsi]=${searchTerm}&sort=id:DESC`;

      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });

    return promise;
  }
  
  async getfournissWithContain(data?) {
    let promise = new Promise<any>((resolve, reject) => {
      // Convert data and attribute to lowercase for case-insensitive search
      // const searchTerm = data ? data.toLowerCase() : '';
      const searchTerm = data ? data : "";

      const attribute = "name";

      let url = `${environment.url}/fournisseurs?filters[${attribute}][$containsi]=${searchTerm}&sort=id:DESC`;

      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });

    return promise;
  }

  async saveArticles(data) {
    let promise = new Promise<any>((resolve, reject) => {
      // let url = `${environment.url}/createProduct?populate=fournisseur&populate=sous_famille&populate=collection&populate=rayon&populate=image`;
      let url = `${environment.url}/createProduct`;

      this.http
        .post(url, data)
        .toPromise()

        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }
  async EditArticle(data, id) {
    let promise = new Promise<any>((resolve, reject) => {
      let url = `${environment.url}/products/${id}`;
      this.http
        .put(url, data)
        .toPromise()

        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }
  deleteArticle(id) {
    let promise = new Promise<any>((resolve, reject) => {
      let url = `${environment.url}/products/` + id;
      this.http
        .delete(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }
  async getSousFamille() {
    let promise = new Promise<any>((resolve, reject) => {
      let url = `${environment.url}/sous-familles?populate=*`;
      this.http
        .get(url)
        .toPromise()

        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }
  async getCollection() {
    let promise = new Promise<any>((resolve, reject) => {
      let url = `${environment.url}/collections`;
      this.http
        .get(url)
        .toPromise()

        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }
  async getRayons() {
    let promise = new Promise<any>((resolve, reject) => {
      let url = `${environment.url}/rayons?populate=*`;
      this.http
        .get(url)
        .toPromise()

        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }
  async getNature() {
    let promise = new Promise<any>((resolve, reject) => {
      let url = `${environment.url}/natures?populate=*`;
      this.http
        .get(url)
        .toPromise()

        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }

  async getMasques() {
    let promise = new Promise<any>((resolve, reject) => {
      let url = `${environment.url}/masque-tailles?populate=tailles_masques`;
      this.http
        .get(url)
        .toPromise()

        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }
  async getCouleurs() {
    let promise = new Promise<any>((resolve, reject) => {
      let url = `${environment.url}/Colors`;
      this.http
        .get(url)
        .toPromise()

        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }
  // private apiUrl = "https://prefacture.rosace.com.tn/api/configuration";

  getTVA(): Observable<any> {
    let url = `${environment.url}/configuration`;

    return this.http.get<any>(url);
  }
  gettva() {
    // return this.http.get<any>(this.apiUrl);
    let promise = new Promise<any>((resolve, reject) => {
      let url = `${environment.url}/configuration`;
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }
  async getproduct() {
    let promise = new Promise<any>((resolve, reject) => {
      const params = new HttpParams()
        .set("populate", "variations")
        .append("populate", "achats")
        .append("populate", "image")
        .append("populate", "fournisseur")
        .append("populate", "variations.color")
        .append("populate", "variations.taille")
        .set("sort", "id:DESC")
        .set("pagination[pageSize]", this.pageSize)
        .set("pagination[page]", this.currentPage);

      // let url = `${environment.url}/products?populate=variations&populate=achats&populate=image&populate=fournisseur
      // &populate=variations.color&populate=variations.taille&sort=id:DESC&pagination[pageSize]=25&pagination[page]=1`;
      let url = `${environment.url}/products?`;
      this.http
        .get(url, { params })
        .toPromise()

        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }

  async getVariationProduct(id) {
    let promise = new Promise<any>((resolve, reject) => {
      let url =
        `${environment.url}/products/` +
        id +
        `?populate=variations&populate=variations.color&populate=variations.taille`;
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }

  async gettaille() {
    let promise = new Promise<any>((resolve, reject) => {
      let url = `${environment.url}/tailles`;
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }
  async getcolor() {
    let promise = new Promise<any>((resolve, reject) => {
      let url = `${environment.url}/colors?`;
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }

  async getVariationP(id) {
    let promise = new Promise<any>((resolve, reject) => {
      let url =
        `${environment.url}/products/` +
        id +
        `?populate=variations&populate=variations.color&populate=variations.taille`;
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }

  async editProducts(data, id?) {
    let promise = new Promise<any>((resolve, reject) => {
      // let url = `${environment.url}/products/`+id+`?
      let url = `${environment.url}/updateProduct `;
      this.http
        .post(url, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }

  async addarticle(data) {
    let promise = new Promise<any>((resolve, reject) => {
      let url = `${environment.url}/products?populate=image&populate=variations&populate=variations.color&populate=variations.taille&populate=fournisseur`;
      // let url = `${environment.url}/products?populate=variations`;
      this.http
        .post(url, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          // this.handleError(err);
          console.log("error in adding article ");
          this.dataToParent = "error";
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }

  // private handleError(error: HttpErrorResponse): Observable<never> {
  //   this.error = error.message;
  //   return of();
  // }
  async editvariation(data, id) {
    let promise = new Promise<any>((resolve, reject) => {
      let url =
        `${environment.url}/variations/` +
        id +
        `?&populate=color&populate=taille`;
      this.http
        .put(url, { data })
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }

  async getfournisseur() {
    let promise = new Promise<any>((resolve, reject) => {
      let url =
        `${environment.url}/fournisseurs?filters[name][$contains]=` +
        this.fournisseur;
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }

  async getFournisseurs() {
    let promise = new Promise<any>((resolve, reject) => {
      let url = `${environment.url}/fournisseurs`;
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }

  /*async deleteVariation(id) {
    return this.http.delete<any>(environment.url + "variations/" + id);
  }*/

  async deleteVariation(id) {
    let promise = new Promise<any>((resolve, reject) => {
      let url = `${environment.url}/variations/` + id;
      this.http
        .delete(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }
  async deleteProduct(id) {
    let promise = new Promise<any>((resolve, reject) => {
      let url = `${environment.url}/products/` + id;
      this.http
        .delete(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }
  async addvariations(data) {
    let promise = new Promise<any>((resolve, reject) => {
      let url = `${environment.url}/variations?populate=color&populate=taille`;
      this.http
        .post(url, data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }
  async getVariationsOfProd(code) {
    let promise = new Promise<any>((resolve, reject) => {
      let url =
        `${environment.url}/variations?variations?filters[product][id][$eq]=` +
        code;
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }
}
