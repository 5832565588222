import { Component, OnInit } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { AuthService } from './demo/service/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[];

    constructor(public appMain: AppMainComponent, private authService: AuthService, private router: Router) {}

    ngOnInit() {
        this.model = [
          /*  
            {
                label: 'UI Kit', icon: 'pi pi-fw pi-star-fill', routerLink: ['/uikit'],
                items: [
                    {label: 'Form Layout', icon: 'pi pi-fw pi-id-card', routerLink: ['/uikit/formlayout']},
                    {label: 'Input', icon: 'pi pi-fw pi-check-square', routerLink: ['/uikit/input']},
                    {label: 'Float Label', icon: 'pi pi-fw pi-bookmark', routerLink: ['/uikit/floatlabel']},
                    {label: 'Invalid State', icon: 'pi pi-fw pi-exclamation-circle', routerLink: ['/uikit/invalidstate']},
                    {label: 'Button', icon: 'pi pi-fw pi-mobile', routerLink: ['/uikit/button'], class: 'rotated-icon'},
                    {label: 'Table', icon: 'pi pi-fw pi-table', routerLink: ['/uikit/table']},
                    {label: 'List', icon: 'pi pi-fw pi-list', routerLink: ['/uikit/list']},
                    {label: 'Tree', icon: 'pi pi-fw pi-share-alt', routerLink: ['/uikit/tree']},
                    {label: 'Panel', icon: 'pi pi-fw pi-tablet', routerLink: ['/uikit/panel']},
                    {label: 'Overlay', icon: 'pi pi-fw pi-clone', routerLink: ['/uikit/overlay']},
                    {label: 'Media', icon: 'pi pi-fw pi-image', routerLink: ['/uikit/media']},
                    {label: 'Menu', icon: 'pi pi-fw pi-bars', routerLink: ['/uikit/menu'], preventExact: true},
                    {label: 'Message', icon: 'pi pi-fw pi-comment', routerLink: ['/uikit/message']},
                    {label: 'File', icon: 'pi pi-fw pi-file', routerLink: ['/uikit/file']},
                    {label: 'Chart', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/uikit/charts']},
                    {label: 'Misc', icon: 'pi pi-fw pi-circle-off', routerLink: ['/uikit/misc']}
                ]
            },
            {
                label:'Prime Blocks', icon:'pi pi-fw pi-prime', routerLink: ['/blocks'],
                items:[
                    {label: 'Free Blocks', icon: 'pi pi-fw pi-eye', routerLink: ['/blocks']},
                    {label: 'All Blocks', icon: 'pi pi-fw pi-globe', url: ['https://www.primefaces.org/primeblocks-ng'], target: '_blank'},
                ]
            },
            {
                label: 'Utilities', icon: 'pi pi-fw pi-compass', routerLink: ['/utilities'],
                items: [
                    {label: 'PrimeIcons', icon: 'pi pi-fw pi-prime', routerLink: ['utilities/icons']},
                    {label: 'PrimeFlex', icon: 'pi pi-fw pi-desktop', url: ['https://www.primefaces.org/primeflex/'], target: '_blank'},
                ]
            },*/
            {label: 'Acceuil', icon: 'pi pi-fw pi-home', routerLink: ['/pages/Acceuil']},
            {label: 'fournisseurs', icon: 'bi bi-person-fill-down', routerLink: ['pages/list-fournisseurs']},
            {label: 'Clients', icon: 'bi bi-person-fill-up', routerLink: ['pages/ClientGestion']},
            {label: 'Paramétre', icon: 'bi bi-gear' ,routerLink: ['/pages/parametre']
            // items: [
            //       {label: 'Sous Familles-Collections-Rayons',  routerLink: ['/pages/parametre']},

            //     // {label: 'Sous Famille',  routerLink: ['/pages/sous-famille']},
            //     // {label: 'Collection', routerLink: ['/pages/collection']},
            //     // {label: 'Rayon',  routerLink: ['/pages/rayon']},
            // ]
        },
            {label: 'Articles', icon: 'bi bi-box', items: [
                {label: 'Gérer Articles',  routerLink: ['/pages/list-articles/list-articles']},
                {label: 'Consultation Articles',  routerLink: ['/pages/consulte-article']},
            ]},

            // {label: 'Achats', icon: 'bi bi-bag', routerLink: ['/pages/achat']},
            {label: 'Achat', icon: "bi bi-arrow-left-circle",
            items: [
                {label: 'Facture Achats',  routerLink: ['/pages/facture-achats']},
                {label: 'Consultation Achats',  routerLink: ['/pages/consulte-achats']},
            ]
        },
        {label: 'Vente', icon: "bi bi-arrow-right-circle" ,
        items: [
            {label: 'Saisie Ticket',  routerLink: ['/pages/facture-ventes']},
            {label: 'Consultation Ticket', routerLink: ['/pages/consultation-ticket']},
            {label: 'Facture Ventes',  routerLink: ['/pages/generer-facture']},
            {label: 'Consultation Facture', routerLink: ['/pages/consultation-facture']},
            {label: 'Consultation Ticket Z', routerLink: ['pages/ticketZ']},
           // {label: 'Facture Ticket Z', routerLink: ['pages/ticketZFacture']},

        ]
    },
            {label: 'Stocks', icon: 'bi bi-arrow-down-circle',
            items: [
                { label: 'Consultation stock', routerLink: ['/pages/stock-article/stock-article'] },
                { label: 'Mouvement stock', routerLink: ['/pages/mouvement-stock/mouvement-stock'] },

            ]
        },
       

        // {label: 'Articles', icon: 'bi bi-box-seam', routerLink: ['/pages/product/product']},

            // {label: 'Articles', icon: 'bi bi-box-seam', routerLink: ['/pages/list-articles/list-articles']},
          
           


            {label: 'Utilisateurs', icon: 'bi bi-people-fill', routerLink: ['/pages/users']},
              {label: 'Export', icon: 'bi bi-people-fill', routerLink: ['/pages/export']},
            {label: 'Deconnexion', icon: 'bi bi-box-arrow-right', command: () => this.logout()},




       /*  {
                label: 'Pages', icon: 'pi pi-fw pi-copy', routerLink: ['/pages'],
                items: [
                  //  {label: 'Crud', icon: 'pi pi-fw pi-pencil', routerLink: ['/pages/crud']},
                    {label: 'Users', icon: 'pi pi-fw pi-pencil', routerLink: ['/pages/users']},
                    // {label: 'fournisseur', icon: 'pi pi-fw pi-pencil', routerLink: ['/pages/fournisseur']},
                    {label: 'fournisseur', icon: 'pi pi-fw pi-pencil', routerLink: ['pages/list-fournisseurs']},

                    // {label: 'article', icon: 'pi pi-fw pi-pencil', routerLink: ['/pages/article']},
                    {label: 'article', icon: 'pi pi-fw pi-pencil', routerLink: ['/pages/product/product']},
                    {label: 'Stock', icon: 'pi pi-fw pi-pencil',
                    items: [
                        { label: 'Par Article', icon: 'pi pi-fw pi-pencil', routerLink: ['/pages/stock-article/stock-article'] },
                    ]
                
                },

                    {label: 'Achats', icon: 'pi pi-fw pi-pencil', routerLink: ['/pages/achat']},
                   /* {label: 'Calendar', icon: 'pi pi-fw pi-calendar-plus', routerLink: ['/pages/calendar']},
                    {label: 'Timeline', icon: 'pi pi-fw pi-calendar', routerLink: ['/pages/timeline']},
                    { label: 'Landing', icon: 'pi pi-fw pi-globe', url: 'assets/pages/landing.html', target: '_blank' },

                    { label: 'Login', icon: 'pi pi-fw pi-sign-in', routerLink: ['/login'], target: '_blank' },
                    { label: 'Error', icon: 'pi pi-fw pi-exclamation-triangle', routerLink: ['/error'], target: '_blank' },
                    { label: '404', icon: 'pi pi-fw pi-times', routerLink: ['/404'], target: '_blank' },
                    {label: 'Access Denied', icon: 'pi pi-fw pi-ban', routerLink: ['/accessdenied'], target: '_blank'},
                    { label: 'Empty', icon: 'pi pi-fw pi-clone', routerLink: ['/pages/empty'] },
                ]
            },   */
           /* {
                label: 'Hierarchy', icon: 'pi pi-fw pi-sitemap',
                items: [
                    {
                        label: 'Submenu 1', icon: 'pi pi-fw pi-sign-in',
                        items: [
                            {
                                label: 'Submenu 1.1', icon: 'pi pi-fw pi-sign-in',
                                items: [
                                    { label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-sign-in' },
                                    { label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-sign-in' },
                                    { label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-sign-in' },
                                ]
                            },
                            {
                                label: 'Submenu 1.2', icon: 'pi pi-fw pi-sign-in',
                                items: [
                                    { label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-sign-in' }
                                ]
                            },
                        ]
                    },
                    {
                        label: 'Submenu 2', icon: 'pi pi-fw pi-sign-in',
                        items: [
                            {
                                label: 'Submenu 2.1', icon: 'pi pi-fw pi-sign-in',
                                items: [
                                    { label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-sign-in' },
                                    { label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-sign-in' },
                                ]
                            },
                            {
                                label: 'Submenu 2.2', icon: 'pi pi-fw pi-sign-in',
                                items: [
                                    { label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-sign-in' },
                                ]
                            },
                        ]
                    }
                ]
            },
            {
                label: 'Docs', icon: 'pi pi-fw pi-file', routerLink: ['/documentation']
            },
            {
                label: 'Buy Now', icon: 'pi pi-fw pi-money-bill', url: ['https://www.primefaces.org/store']
            }*/
        ];
    }
    private logout() {
        this.authService.logout();
    
        this.router.navigate(['/login']);
      }
    onMenuClick() {
        this.appMain.menuClick = true;
    }
}
