import { Component, OnInit , ViewChild , Output, EventEmitter} from '@angular/core';
import { ShareticketService } from 'src/app/demo/service/shareticket.service';
import { GenererVenteService } from 'src/app/demo/service/generer-vente.service';
import { ConfigurationService } from 'src/app/demo/service/configuration.service';
import { AddVenteDialogComponent } from '../add-vente-dialog/add-vente-dialog.component';
import { VenteService } from 'src/app/demo/service/vente.service';
interface Variation {

  id: number;
  quantity: number;
  remise: number | null;
  prix_produit: number;
  ht: number | null;
  tva: number | null;
  code_article_generique: string;
  expanded:boolean
}

interface client {
  id : string ,
  name: string;
  clientcode: string;
  address: string ;
  phone: string;
  
}
interface Vente {

  id: number;
  pourcentage_remise: number;
  remise: number;
  ht: number;
  taxes: number;
  ttc: number;
  total_fodec: number;
  timbre: number;
  prix_net: number;
  uuid: string;
  createdAt: string;
  updatedAt: string;
  // code_facture_client: string;
  numeroTicket: string;
  date_vente: string;
  client : string ;
  store : string ;
  etablissement : string ;
  selected?: boolean;
  product?: string ;
  productids? : any[];
  designation? : string ;
  clientcode : string ;
  clientadress: string ;
  clienttelephone 

}
interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}

@Component({
  selector: 'app-details-ticket',
  templateUrl: './details-ticket.component.html',
  styleUrls: ['./details-ticket.component.scss']
})
export class DetailsTicketComponent implements OnInit {
  @Output() refresh = new EventEmitter<void>();
  @ViewChild(AddVenteDialogComponent) addVenteDialog: AddVenteDialogComponent;
  Clients
  ClientInfo: client;
  filtredClient
  selectedVente: Vente | undefined;
  modifier : boolean = false ;
  event: AutoCompleteCompleteEvent
  // --------------- modifier decalaration ----------------------- >
  netToPayAmount
  remisePercentage
  tva :number = 19 ;
  fodec 
  timbre
  dimension
  total : number = 0 ;
  htAmount
  remiseAmount
  totalFodec
  taxesAmount
  timbreAmount
  ttcAmount
  showFodec = false 
  htArray: number[] = [];
  ttcArray: number[] = [];
  opened = false ;
  // -------------------------------------------------------------->
  opendialog(bool : boolean){
      this.opened = true ;
    this.modifier= bool ;
    this.visible = true ;
    this.selectedVente = null;
    //--------------  les Remises ----------------//
    this.remisePercentage = null;
    this.remiseAmount = null ;
    //--------------  Ht tva ttc --------------//
    this.htAmount = null;
    this.tva = null;
    this.ttcAmount = null;
    //---------- fodec timbre ticket --------------//
    this.fodec = null;
    this.timbre = null;
    this.netToPayAmount = null
    this.total = null
    this.GroupeVariations = [] ;
    this.ngOnInit();
  }

  visible: boolean = false;
  constructor(
    private sharedService: ShareticketService,
     private GenerVente :GenererVenteService,
     private configuration :ConfigurationService ,
     private venteService:VenteService,
  ) {
    this.expandedRows = [];
    }
GroupeVariations 

variations: Variation[] = [];

// ------------------------- grouper variations -----------------------------//

groupVariationsByCodeAndRemise(variations: Variation[]): any[] {
  const groupedVariations: any[] = [];
  variations.forEach(variation => {
    const existingGroup = groupedVariations.find(group =>
      group.code_article_generique === variation.code_article_generique &&
      group.remise === variation.remise
    );
    if (existingGroup) {
      existingGroup.variations.push(variation);
      existingGroup.montant += Number((variation.ht * variation.quantity));
      existingGroup.quantite += variation.quantity
    } else {
      const newGroup = {
        code_article_generique: variation.code_article_generique,
        remise: variation.remise,
        variations: [variation],
        montant: Number((variation.ht * variation.quantity)),
        expanded : false , 
        quantite : Number(variation.quantity) 
      };
      groupedVariations.push(newGroup);
    }
  });
  console.log('grouping variants is : ' , groupedVariations)
  
  return groupedVariations;
}
groupNewVariations(variations: Variation[]) {
  variations.forEach(variation => {
    const existingGroup = this.GroupeVariations.find(group =>
      group.code_article_generique === variation.code_article_generique &&
      group.remise === variation.remise
    );

    if (existingGroup) {

      existingGroup.variations.push(variation);
      existingGroup.montant += Number((Number(variation.ht) * Number(variation.quantity)));
      existingGroup.quantite += Number(variation.quantity)
    } else {
     
      const newGroup = {
        code_article_generique: variation.code_article_generique,
        remise: variation.remise || 0 ,
        variations: [variation],
        montant:  Number((variation.ht * variation.quantity)),
        expanded : false ,
        quantite : Number(variation.quantity) 
      };
      this.GroupeVariations.push(newGroup);
    }
  });
}

//----------------------------------------- Client ----------------------------------------------//
getSelectedClient(){
  console.log("selected client",this.ClientInfo)

}

async getClients(){
 await this.venteService.getClients().then(res=>{
    // console.log('client',res)
  this.Clients=res.data.map(item=>({id:item.id,name:item.attributes.name ,
    clientcode: item.attributes.code,
    address: item.attributes.adresse,
    phone: item.attributes.telephone, }
    ))
//   this.Clients.forEach(client => {
//     client.name === this.ClientInfo.name ? this.ClientInfo.id = client.id : client.name ;
//   });
})
 
}

filterClient(event: AutoCompleteCompleteEvent) {
  let filtered: any[] = [];
  let query = event.query;

  for (let i = 0; i < (this.Clients as any[]).length; i++) {
      let country = (this.Clients as any[])[i];
        const find = filtered.find(f => f === country )
        
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(country);
      }
  }

  this.filtredClient = filtered;
}

isloading = false ;
loading = false ;

// ------------------------------ initialiation ----------------------------------------//


     async ngOnInit(){
         if (this.opened === true){
         this.isloading = true ;
      await this.getClients()
      // --------------- confugation de remise et fodec ----------------------- //
     await this.getConfiguration()
      let list =  [];
      // ---------------------  Servie vente ------------------------------- // 
      this.sharedService.currentVenteData.subscribe( async (vente) => {
        
        this.selectedVente = vente;
      //  this.fodec = this.selectedVente.total_fodec ? this.selectedVente.total_fodec : 0
        this.timbre = this.selectedVente.timbre ? this.selectedVente.timbre : 0
        this.remisePercentage = this.selectedVente.pourcentage_remise;
        this.remiseAmount = this.selectedVente.remise ;
        //------------------------//
        this.htAmount = this.selectedVente.ht;
        this.taxesAmount = this.selectedVente.taxes;
        this.ttcAmount = this.selectedVente.ttc;
        //------------------------//
        this.totalFodec = this.selectedVente.total_fodec;
        this.netToPayAmount = this.selectedVente.prix_net
        this.total = this.remiseAmount > 0 ?  this.selectedVente.ht + this.remiseAmount  : this.selectedVente.ht
        console.log(this.total)

        list = [vente.id]
        console.log(list)

      // --------------------------- client info -------------------------------- //
      
      this.ClientInfo = this.Clients.find( client  => client.name = vente.client) 
      console.log('client info',this.ClientInfo)
     
    });
    await this.GenerVente.getVariations(list).then(
      (response) => {
          
        if (response && response.data) {
          this.variations = response.data.map((item) => {
            const attributes = item.attributes;
            console.log('les variation sonttt :' , item)
            return {
              id: attributes.variation.data.id,
              quantity: attributes.quantity,
              remise: attributes.remise == null ? 0 : attributes.remise ,
              prix_produit: attributes.prix_produit,
              ht: attributes.ht,
              tva: attributes.tva,
              code_article_generique: attributes.variation.data.attributes.code_article_generique,
            };
          });
        } else {
          console.error('Invalid variations response:', response);
        }
      },
      (error) => {
        console.error('Error fetching variations', error);
      }
    );
    this.htArray = this.variations.map(variation => this.calculateOriginalPrice(variation.ht, variation.remise) || 0);
    this.ttcArray = this.variations.map(variation =>  this.calculateOriginalPrice(variation.prix_produit, variation.remise) || 0);
    this.GroupeVariations = this.groupVariationsByCodeAndRemise(this.variations)
    console.log('groupe var' ,this.GroupeVariations )
    this.isloading = false
    
     }
    }
  // --------------------- update table ------------------------------------- //
  onQuantityChange(newQuantity: number, ri, group : number) {
    console.log(group)
    // const vi = this.GroupeVariations[group].variations.findIndex(p => p === group)
    // console.log(vi)
    console.log(this.GroupeVariations)
    
    this.GroupeVariations[group].quantite = newQuantity;
    this.GroupeVariations[group].montant = this.GroupeVariations[group].variations[0].ht * newQuantity;
     const all = this.GroupeVariations[group].variations.reduce((sum, v) => sum  + v.quantity , 0);
     this.GroupeVariations[group].variations[0].quantity = this.GroupeVariations[group].variations[0].quantity + (newQuantity - all)
    
    this.updatemontant()
  }
  updatemontant(){

    this.total = this.GroupeVariations.reduce((sum, group) => sum + Number(group.montant), 0);

    this.updateValues()
  }
  onRemiseChange(newRemise: number, rowIndex: number, groupIndex: number) {
    const variationsBeforeGroup = this.GroupeVariations.slice(0, groupIndex).reduce((total, group) => total + group.variations.length, 0);
    const overallIndex = variationsBeforeGroup + rowIndex;
    this.GroupeVariations[groupIndex].remise = newRemise;
    const newHt = this.htArray[variationsBeforeGroup] - (this.htArray[variationsBeforeGroup] * newRemise / 100 );
    const newttc = this.ttcArray[variationsBeforeGroup] -(this.ttcArray[variationsBeforeGroup] * newRemise / 100 );
    this.GroupeVariations[groupIndex].variations.forEach(variation => { 
      variation.ht = newHt;
      variation.prix_produit = newttc;
      variation.remise = newRemise ;
    });
    this.GroupeVariations[groupIndex].montant = (this.GroupeVariations[groupIndex].variations.reduce((sum, v) => sum + (v.ht * v.quantity), 0));
    console.log('le montant est :',this.GroupeVariations[groupIndex]);
    this.updatemontant()
  }
  deletealldata(ri){
    this.GroupeVariations.splice(ri, 1);
    this.variations.splice(ri,1);
    this.updatemontant();
  }
  deletedata(rowIndex , ri){
    this.GroupeVariations[ri].variations.splice(rowIndex, 1);
    this.updatemontant()
  }
  //---------------------------- calculations----------------------------------------- //
  calculateOriginalPrice(finalPrice: number, remisePercentage: number): number | null {
      return finalPrice / ( 1 - remisePercentage / 100);
  }
  //------------------------------------- table expand  -------------------------------------- //
  expandedRows: number[] = [];
  toggleRow(group: any, rowIndex: number) {
    group.expanded = !group.expanded;
  
    if (group.expanded) {
      this.expandedRows.push(rowIndex);
    } else {
      const indexToRemove = this.expandedRows.indexOf(rowIndex);
      if (indexToRemove !== -1) {
        this.expandedRows.splice(indexToRemove, 1);
      }
    }
  }
  setData(vente: Vente) {
  //  this.selectedVente = vente;
  }
 // ----------------------------------------- modifier functions -------------------------------->
 
   onDateChange(newDate: Date) {
    let previousDay = new Date(newDate);
    previousDay.setDate(newDate.getDate() + 1);
   this.postdate = previousDay ;
  }
  
  postdate
 
  async getConfiguration(){
    this.fodec = null ;
    await this.configuration.getConfig().then(data=>{
      console.log("config",data)
      this.tva=data.data.attributes.tva
      console.log("tva",  this.tva)
      const fodec=data.data.attributes?.fodec
      console.log("fodec",fodec)
      const timbre=data.data.attributes?.timbre
      console.log("timbre",timbre)
      if(fodec>0){
        this.showFodec=true
        console.log('configuration de fodec est :',fodec)
        this.fodec =fodec
      }
      if(timbre){
        this.timbre=parseFloat(timbre)
      }
      this.dimension=data.data.attributes.article_dimensionne
      console.log("dimonsion",this.dimension)
    })
  }
  validateInput() {
    if (this.remisePercentage < 0) {
      this.remisePercentage = 0;
    } else if (this.remisePercentage > 100) {
      this.remisePercentage = 100;
    }
  }
  updateValues() {
    // this.htAmount = this.remisePercentage == 0.0 ? this.total :  (this.total * (100-this.remisePercentage))/100 ;
  const remisePercentageAsNumber = parseFloat(this.remisePercentage);
  const totalAsNumber = this.total;

// Use isNaN to check if the conversion was successful.
if (!isNaN(remisePercentageAsNumber) && !isNaN(totalAsNumber)) {
  // Perform the calculation with the numeric values.
  this.htAmount = remisePercentageAsNumber === 0.0 ? totalAsNumber : (totalAsNumber * (100 - remisePercentageAsNumber)) / 100;
} else {
  console.error('Invalid input. Please check remisePercentage and total for proper numeric values.');
}

    this.remiseAmount = (this.total * this.remisePercentage)/100;

    this.totalFodec =(this.htAmount*this.fodec)/100;
    this.taxesAmount = (((this.htAmount + this.totalFodec)* this.tva)/100) ;
    this.ttcAmount = this.htAmount + this.taxesAmount ;
    this.netToPayAmount = this.ttcAmount + this.timbre +  this.totalFodec;

  }

 // ----------------------------------------- ajouter article  -------------------------------->

 receiveTicket(data){
    console.log('ticket from child',data)
    console.log('ticket from child',data.VenteVariations)
    const newvaar : Variation[] = [];
      for(let variationdata of data.VenteVariations) {
      const newdata = {
        id: variationdata.ID,
        quantity: Number(variationdata.QTE),
        remise:  Number(data.remise),
        prix_produit: Number(data.prixTTC),
        ht: Number(data.prixHT),
        tva: Number(this.tva),
        code_article_generique: data.article.code_article_generique,
        expanded: false // Set to the default value you want
      };
      newvaar.push(newdata)
      this.variations.push(newdata)
    };
    if(!this.selectedVente.productids){
        this.selectedVente.productids=[]
    }
    console.log('consolemed',this.selectedVente)
    this.selectedVente.productids.push(data.article.id)
    this.htArray = this.variations.map(variation => {
      const remiseAmount = (variation.remise * variation.prix_produit) / 100;
      return variation.ht + (isNaN(remiseAmount) ? 0 : remiseAmount);
    });
    
    this.ttcArray = this.variations.map(variation => {
      const remiseAmount = (variation.remise * variation.prix_produit) / 100;
      return variation.prix_produit + (isNaN(remiseAmount) ? 0 : remiseAmount);
    });
    console.log('new variation' , newvaar)
      this.groupNewVariations(newvaar)
      console.log('final  :' ,this.GroupeVariations )
      this.updatemontant()
     }
    async openDialog(){
      const DetailsVenteParent={etablissement:'',store:''}
      console.log( DetailsVenteParent)
      this.addVenteDialog.showDialog(DetailsVenteParent)
      }

      //--------------------------------- button ------------------------- //

     async  updateTicket(){
        console.log(this.GroupeVariations)
        console.log(this.selectedVente.id)
        let ventvariation = []
        console.log('test console variation',this.variations)
        for ( const  vente of this.variations ){
          const variation = {
            variation: vente.id,
            quantity: vente.quantity,
            remise: vente.remise,
            prix_produit: vente.prix_produit,
            ht: vente.ht,
            tva: vente.tva,
          };
          console.log(' la venteeeeeeeeeeeeeeeee ',vente.id)
          ventvariation.push(variation);
        }
        
        const currentDate = this.postdate ? this.postdate : new Date(this.selectedVente.date_vente);
        const formattedDate = currentDate.toISOString();
        const data = {
          
            "vente" : {
            "date_vente" : formattedDate , 
              "id": this.selectedVente.id,
              "products": this.selectedVente.productids,
              "etablissement": this.selectedVente.etablissement,
              "client":  parseInt(this.ClientInfo.id) ,
              "pourcentage_remise": 0,
              "remise": 0,
              "ht": this.htAmount,
              "taxes": this.taxesAmount,
              "ttc": this.ttcAmount,
              "total_fodec": this.totalFodec,
              "timbre": this.timbre,
              "prix_net": this.netToPayAmount,
              "status_vente": 1
            },
            "variationsVente": ventvariation
        }
        console.log(data)
        console.log(this.selectedVente)
        console.log(this.variations)
        this.loading = true
        try{
        const response = await this.venteService.updatefacture(data);
            
        }catch (e) {
            
        } finally {
            this.loading = false 
        }
        this.refresh.emit();
        this.visible = false
      }

      async  confimrticket(){

        const data = {
          "data" : {
              "status_vente" : 2
          }
        }
        const response = await this.venteService.updateTicket(data, this.selectedVente.id.toString());
        console.log(response.status)
        this.refresh.emit();
        this.visible = false
      }
     
}
