import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
interface Facture {
  id: number;
  attributes: {
    numero_facture: string;
    date_facture: string;
    prix: number;
    createdAt: string;
    updatedAt: string;
    name: null | string;
    companyName: null | string;
    client_name: null | string;
    clientcode: null | string;
    client_address: null | string;
    client_phone: null | string;
    quantiteTotal: null | string;
    sumoffodec: null | string;
    timbre: null | string;
    fodec: null | string;
    sumOfTaxes: null | string;
    sumOfHt: null | string;
    netpaye: null | string;
    company_address: null | string;
    company_registrationNumber: null | string;
    company_rc: null | string;
    company_phone: null | string;
    company_fax: null | string;
    company_email: null | string;
    company_taxIdentification: null | string;
    company_routeAddress: null | string;
    company_imageUrl: null | string;
    ventes: {
      data: Vente[];
    };
    client: {
      data: {
        id: number;
        attributes: {
          name: string;
          code: string;
          adresse: string;
          uuid: string;
          telephone: string;
          createdAt: string;
          updatedAt: string;
        };
      };
    };
    etablissement: {
      data: {
        id: number;
        attributes: {
          name: string;
          createdAt: string;
          updatedAt: string;
        };
      };
    };
    store: {
      data: {
        id: number;
        attributes: {
          name: string;
          code: string;
          createdAt: string;
          updatedAt: string;
        };
      };
    };
    produit_factures: {
      data: ProduitFacture[];
    };
  };
}

interface ProduitFacture {
  id: number;
  attributes: {
    createdAt: string;
    updatedAt: string;
    reference: string;
    designation: string;
    quantite: number;
    prix_uht: number;
    tva: number;
    prix_ttc: number;
    totale_ht: number;
  };
}

interface Vente {
  id: number;
  pourcentage_remise: number;
  remise: number;
  ht: number;
  taxes: number;
  ttc: number;
  total_fodec: number;
  timbre: number;
  prix_net: number;
  uuid: string;
  createdAt: string;
  updatedAt: string;
  code_facture_client: string;
  date_vente: string;
  client : string ;
  store : string ;
  etablissement : string ;
  selected?: boolean;
  product?: string ;
  designation? : string ;
  clientcode : string ;
  clientadress: string ;
  clienttelephone 
}
@Injectable({
  providedIn: 'root'
})
export class FactureServicesService {
  total : number ;
  // private apiUrl = 'https://prefacture.rosace.com.tn/api/factures?populate=ventes&populate=client&populate=etablissement&populate=store&populate=produit_factures';
    private apiUrl = `${environment.url}/factures?populate=ventes&populate=client&populate=etablissement&populate=store&populate=produit_factures`;

  Factures: Facture[] = [];
  private isToastShown: boolean = false;

  constructor(private http: HttpClient, private messageService: MessageService) { }

   //**************************************handle errrors***************************************** */
   private handleApiError(error) {
    // Handle API error status here
    console.error('API call failed:', error);
  
    if (error.status !== 200) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Erreur inattendue' });
      this.isToastShown = true;
      setTimeout(() => {
        this.isToastShown = false;
      }, 5000);
    } 
    
  }
     //******************************************************************************* */

  async getFacture(page ?: number, size ?: number , field?: string , option? : any , filter ?: string , searshdata1? : string , searshdata2? : string ,date?,depot?,client?,article?    ): Promise<any[]> {
    // this.apiUrl = 'https://prefacture.rosace.com.tn/api/factures?populate=ventes&populate=client&populate=etablissement&populate=store&populate=produit_factures';
    this.apiUrl = `${environment.url}/factures?populate=ventes&populate=client&populate=etablissement&populate=store&populate=produit_factures&populate=etablissement_facture`;

    let searchUrl = `filters[$or][0][${searshdata1}][$containsi]=${filter}`;
    searchUrl = searshdata2 ?  `${searchUrl}&filters[$or][1][${searshdata2}][$containsi]=${filter}`: searchUrl
    const sortOrder = option === 1 ? 'ASC' : 'DESC';
    let sort = field && option ? `sort=${field}:${sortOrder}` :  '' ;
       if (!field) {
        sort = `sort=date_facture:DESC&`
    }
    const pagin = `pagination[page]=${page}&pagination[pageSize]=${size}`;
    let GetApi = ''
    if(filter && filter != ''){
       GetApi = `${this.apiUrl}&${pagin}&${sort}&${searchUrl}`
    }else{
       GetApi = `${this.apiUrl}&${pagin}&${sort}`
    }
    if (client && client.length > 0) {
      for(let clien of client){
        GetApi += `&filters[client][name][$eq]=${clien.name}`;
      }
    }
   
      if (depot && depot.length > 0) {
        for(let Depot of depot){
  
          GetApi += `&filters[etablissement_facture][name][$eq]=${Depot.name}`;
        }
      }
      if (article && article.length > 0) {
        for(let Article of article){
  
          GetApi += `&filters[produit_factures][reference][$eq]=${Article.code_article_generique}`;
        }
      }
    date ?  GetApi = `${GetApi}${this.StrapiFilterDate(date, 0 , 'date_facture')}` : '' ;
    try {
      const apiResponse: any = await this.http.get(GetApi).toPromise();
      this.total = apiResponse.meta?.pagination?.total 
      return apiResponse;
    } catch (error) {
      console.error('Error fetching clients:', error);
      this.handleApiError(error);
      throw error;
    }
  }  

  // ----------------------------- Date configuration ------------------------------- //
  formatDateToYYYYMMDD(date : any) {

    date.setDate(date.getDate() + 1);
    return date.toISOString();
    
  } 
  
  StrapiFilterDate(date : any[] , count , datefield : string): string {
    let filter = '';
    if (Array.isArray(date)) {

      if (date.length === 2 && date[1] != null) {
        const startDate = this.formatDateToYYYYMMDD(date[0]);
        const endDate = this.formatDateToYYYYMMDD(date[1]);
        filter += `&filters[$and][${count}][${datefield}][$gte]=${startDate}`
        count += 1;
        filter += `&filters[$and][${count}][${datefield}][$lte]=${endDate}`;
      } else if (date.length === 2 && date[1] == null) {
        const formattedDate = this.formatDateToYYYYMMDD(date[0]);
        filter += `&filters[$and][${count}][${datefield}][$eq]=${formattedDate}`;
      } else {
        console.error('Invalid date range:', date);
      }
    } else {
      const formattedDate = this.formatDateToYYYYMMDD(date);
      filter += `&filters[$and][${count}][${datefield}][$eq]=${formattedDate}`;
    }
    return filter
  }

  //----------------------------------------------------------------------------//
  async getFactureID(id ): Promise<any[]> {
    this.apiUrl =`${environment.url}/factures`;
    try { const GetApi = `${this.apiUrl}/${id}${'?populate=ventes&populate=client&populate=etablissement&populate=store&populate=produit_factures'}`
      const apiResponse: any = await this.http.get(GetApi).toPromise();
      this.total = apiResponse.meta?.pagination?.total 
      return apiResponse;
    } catch (error) {
      console.error('Error fetching clients:', error);

      this.handleApiError(console);
      throw error;
    }
  }  
  
  async getFacturebynumero(numero): Promise<any> {
    // this.apiUrl = 'https://prefacture.rosace.com.tn/api/factures?populate=ventes&populate=client&populate=etablissement&populate=store&populate=produit_factures';
    this.apiUrl = `${environment.url}/factures?populate=ventes&populate=client&populate=etablissement&populate=store&populate=produit_factures&filters[numero_facture][$eq]=${numero}`;

  
    try {
      const apiResponse: any = await this.http.get(this.apiUrl).toPromise();
      this.total = apiResponse.meta?.pagination?.total 
      return apiResponse;
    } catch (error) {
      console.error('Error fetching clients:', error);
      this.handleApiError(error);
      throw error;
    }
  }  



  /**
   * The function `getVentes` is an asynchronous function that retrieves a list of sales invoices with
   * pagination support.
   * @param {number} page - The "page" parameter is used to specify the page number of the results you
   * want to retrieve from the API. It is typically used for pagination purposes, where the API returns
   * a large set of data divided into multiple pages.
   * @param {number} size - The "size" parameter represents the number of items to be returned per page
   * in the API response. It determines the size of each page in the pagination.
   * @returns a Promise that resolves to an array of Facture objects.
   */
  async getVentes(page: number, size: number): Promise<Facture[]> {
    const pagin = `pagination[page]=${page}&pagination[pageSize]=${size}`;
    const API = `${this.apiUrl}&${pagin}`;
    try {
      const response = await this.http.get<any>(API).toPromise();
      return response; // Adjust to the actual structure of your API response
    } catch (error) {
      console.error('Error fetching data:', error);
      this.handleApiError(error);
      throw error;     }
  }

  async getFactureContains(data?) {
    let promise = new Promise<any>((resolve, reject) => {
      // Convert data and attribute to lowercase for case-insensitive search
      // const searchTerm = data ? data.toLowerCase() : '';
      const searchTerm = data ? data : "";

      const attribute = "code_article_generique";

      let url = `${environment.url}/factures?filters[numero_facture][$containsi]=${data}`

      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });

    return promise;
  }
  async getFact() {
    let promise = new Promise<any>((resolve, reject) => {
    

      let url = `${environment.url}/factures?`

      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });

    return promise;
  }
}

