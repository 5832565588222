<div class="grid">
	<div class="col-12">
		<div class="card">
			<!-- <p-toast></p-toast> -->

            <h5>Facture Ventes</h5>
			<div class="container">

				<!--<p-autoComplete  [(ngModel)]="selectedEtablissement"  [suggestions]="filtredEtablissement"  (completeMethod)="filterEtablissement($event)"  placeholder="Etablissement" -->
				<!--field="name" [dropdown]="true"   (onSelect)="getSelectedEatb()" style = "margin-right : 10px;"-->
				<!--></p-autoComplete>-->

				<!--<p-autoComplete  [(ngModel)]="selectedStore"  [class.empty-field]="fieldsEmpty" [suggestions]="filtredStore"  (completeMethod)="filterStore($event)" placeholder="Depot"-->
				<!--field="name" [dropdown]="true"   style = "margin-right : 10px;" (onSelect)="getSelecteddepot()"-->
				<!--></p-autoComplete>-->
				
				<p-autoComplete  [(ngModel)]="selectedEtabFact"  [class.empty-field]="fieldsEmpty" [suggestions]="filtredEtabFact"  (completeMethod)="filterEtabFact($event)" placeholder="Etablissement facture"
				field="name" [dropdown]="true"   style = "margin-right : 10px;" (onSelect)="getSelectedEtabFact()"
				></p-autoComplete>

				<p-autoComplete  [(ngModel)]="selectedClient"  [class.empty-field]="fieldsEmpty" [suggestions]="filtredClient"  (completeMethod)="filterClient($event)" placeholder="Client" 
				field="name" [dropdown]="true"   style = "margin-right : 10px;" (onSelect)="getSelectedclient()"
				></p-autoComplete>



				<!-- <p-autoComplete  [(ngModel)]="selectedArticle"  [suggestions]="filteredArticle"  (completeMethod)="filterArticle($event)" placeholder="Article"
				field="code_article_generique" [dropdown]="true"    (onSelect)="getSelectedArticles($event)"
        style = " margin-left: 30px;
        margin-right: 30px;"
				></p-autoComplete> -->



			<p-calendar [(ngModel)]="date"  selectionMode="range"  class="drop" [showIcon]="true" inputId="icon" placeholder="Calendrier"></p-calendar>
					<button pButton pRipple type="button" 
					icon="fa fa-filter bi bi-filter" 
					label="Filtrer" 
					
					class="p-button-success mr-0.5 mb-"
					style="margin-right: 4px;"
					[iconPos]="isPageMinimized() ? 'right' : 'left'"
					(click)="filterVentes()">
			</button>

			<button pButton pRipple type="button" 
					icon="bi bi-arrow-clockwise" 
					label="Refresher" 
					class="btn btn-primary mr-0.5 mb-" 
					style="margin-right: 4px; background-color: rgba(0, 78, 156, 0.158); color: black;"
					[iconPos]="isPageMinimized() ? 'right' : 'left'"
					(click)="resetFilters()">
			</button>
			
                </div>
				<p-table
				[value]="filteredVentes"
				responsiveLayout="stack" 
				scrollHeight="flex"
				[paginator]="false"
				[rows]="5"
				[rowsPerPageOptions]="[5,10, 25, 50]"
				[(selection)]="selectedVentes"
				dataKey="id"
				>
				<ng-template pTemplate="header">
					<tr>
					<th>
						<div class="field-checkbox">
							<p-checkbox [binary]="true" [(ngModel)]="selectAllCheckbox"
							(onChange)="onSelectAllCheckboxChange()"></p-checkbox>
						  </div>
						</th>
						<th>Numero Ticket</th>
						<th>Date</th>
						<th>Client</th>
						<th>Etablissements</th>
						<th>Depot</th>
						<th>Prix</th>
						<th style="width:4em">Détails</th>
						<!-- <th pSortableColumn="Prix" style="width:20%">Prix <p-sortIcon field="Prix"></p-sortIcon></th> -->
					
					  </tr>
					</ng-template>
					<ng-template pTemplate="body" let-vente let-rowIndex="rowIndex">
					  <tr>
						 <td>
						  <div class="field-checkbox">
							<p-checkbox [binary]="true" (ngModelChange)="changeselect(vente)" [(ngModel)]="vente.selected"></p-checkbox>

						  </div>
						</td>
						<td>{{ vente.numeroTicket }}</td>
						<td>{{vente.date_vente}}</td>
						<td>{{ vente.client }}</td>
						<td>{{ vente.etablissement }}</td>
						<td>{{ vente.store }}</td>
						<td>{{ vente.prix_net | roundToThreeDecimals }}</td>
						<td>
							<button type="button" pButton pRipple icon="pi pi-plus" (click)="onCellClick(rowIndex) "></button>
						  </td>
					  </tr>
					</ng-template>
					<ng-template pTemplate="footer" >
						<tr>
							<td></td>
							<td ><p> le total des Prix des articles selectionnées : {{-TotalNetselected | roundToThreeDecimals}} TND</p></td>
							<td></td>
							<td></td>
							<td></td>
						<td></td>
						<td *ngIf="TotalNet || TotalNet > 0"><p> le total des Prix Net est : {{TotalNet | roundToThreeDecimals}} TND</p></td>
						</tr>
					  </ng-template>
				  </p-table>

				  <p-paginator
				  [totalRecords]="totalRecords"
				  [rows]="size"
				  [rowsPerPageOptions]="[5, 10, 25, 50 , 200 , 500]"
				  [pageLinkSize]="5"
				  (onPageChange)="onPageChangePaginator($event)"
				  [alwaysShow]="true"
				></p-paginator>

            <div class="card" style="display: flex; justify-content: space-between; align-items: center;">
                <div style="flex-grow: 1;">
                </div>
			</div>
			<div style="display: flex; margin-top: 20px; justify-content: flex-end;">
				<p-toast></p-toast>
				<p-confirmDialog [style]="{width: '30vw'}" class = "p-element ng-tns-c3435727603-81 ng-star-inserted"></p-confirmDialog>
				<p-button (click) = "showConfirmation1()"  icon="pi pi-check" label="Confirmer"></p-button>		
				  </div>
			<div class="card flex justify-content-center">

				<!-- <p-paginator (onPageChange)="onPageChange($event)"  [totalRecords]="totalItems" [rows]="pageSize" ></p-paginator> -->
			</div>
       </div></div>
	   <app-details-ticket ></app-details-ticket>
	   <app-facture-ticket-pdf  (dialogClose)="resetFilters()"  ></app-facture-ticket-pdf>


